import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import {
  Button,
  CustomCarousel,
  IconEnum,
  Modal,
  SvgIcon,
  TooltipDisclaimer,
} from '~/common/components/ui-elements';
import { CallToAction } from '~/common/models';
import { PortalProvider, usePortals } from 'react-portal-hook';
import {
  colorBlack,
  colorPrimary,
  colorWhite,
  desktopMin,
  tabletMax,
  toCamelCase,
} from '~/common/utils';
import { useRTETooltipPositionCorrector } from '~/common/hooks/use-rteTooltipPositionCorrector';

export interface IVideoCarouselProps {
  id?: number;
  rowTileCount?: number;
  tiles?: IVideoTile[];
  backgroundColor?: string;
  fontColor?: string;
  tileBackgroundColor?: string;
  tileFontColor?: string;
  moreEpisodesText?: string;
  header?: string;
  headerDisclaimer?: string;
  contentCTAs?: CallToAction[];
}

interface IVideoTile {
  header?: string;
  description?: string;
  disclaimer?: string;
  disclaimerFontColor?: string;
  videoSrc?: string;
  embeddedMedia?: string;
  seriesName?: string;
  idx?: number;
  isLargeVideo?: boolean;
}

const breakpoints = {
  desktop: {
    breakpoint: { max: 4000, min: desktopMin },
    items: 4,
  },
  mobile: {
    breakpoint: { max: tabletMax, min: 0 },
    items: 1,
  },
};

interface ModalProps {
  closeModal: () => void;
}

const isPlaying = (_this?: HTMLVideoElement | null) => {
  if (!_this) return null;
  return !_this.paused && _this.currentTime > 0 && !_this.ended;
};

const onVideoEnded = (_this?: HTMLVideoElement | null) => {
  if (_this) {
    _this.currentTime = 0;
  }
};

const onVideoPlayPause = (
  _this?: HTMLVideoElement | null,
  playCallback?: (_this?) => void,
  pauseCallback?: (_this?) => void
) => {
  if (_this) {
    if (isPlaying(_this)) {
      if (pauseCallback) {
        pauseCallback(_this);
        return;
      }
      _this.pause();
    } else {
      if (playCallback) {
        playCallback(_this);
        return;
      }
      _this.play();
    }
  }
};

const renderDisclaimer = (tile: IVideoTile) => {
  if (!tile.disclaimer) return <></>;
  return (
    <TooltipDisclaimer className={cn(styles.MediaDisclaimer)}>
      <p
        dangerouslySetInnerHTML={{
          __html: tile.disclaimer as string,
        }}
        style={{ color: `#${tile.disclaimerFontColor || colorWhite}` }}
      />
    </TooltipDisclaimer>
  );
};

const VideoTileHighlight: React.FC<IVideoTile> = (props) => {
  const { videoSrc, embeddedMedia } = props;
  const modalVideoRef = useRef<HTMLVideoElement>(null);
  const [modalPlaying, setModalPlaying] = useState(false);

  const handleHighlightedVideoClick = (e) => {
    onVideoPlayPause(
      e.target.tagName === 'VIDEO'
        ? e.currentTarget || e.target
        : modalVideoRef.current,
      (el) => {
        el.play();
        setModalPlaying(true);
      },
      (el) => {
        el.pause();
        setModalPlaying(false);
      }
    );
  };

  return (
    <>
      {embeddedMedia ? (
        <div
          dangerouslySetInnerHTML={{
            __html: embeddedMedia as string,
          }}
        />
      ) : (
        <>
          <video
            ref={modalVideoRef}
            disablePictureInPicture
            onEnded={(e: any) => {
              onVideoEnded(e.target);
            }}
            onClick={handleHighlightedVideoClick}
            preload="auto"
            src={videoSrc}
          />
          {!modalPlaying && (
            <SvgIcon
              type={IconEnum.play}
              color={`#${colorBlack}`}
              fill={`#${colorBlack}`}
              size="100%"
              onClick={handleHighlightedVideoClick}
            />
          )}
        </>
      )}
      {(embeddedMedia || videoSrc) && renderDisclaimer(props)}
    </>
  );
};

const VideoTileModal: React.FC<ModalProps & IVideoTile> = ({
  closeModal,
  ...rest
}) => {
  return (
    <Modal
      closeModal={closeModal}
      modalContentClass={cn(styles.ModalContent, {
        [styles.ModalContentEmbed]: rest.embeddedMedia,
      })}
      closeButtonColor={`#${colorWhite}`}
      lockScroll
    >
      <div
        className={cn(styles.MediaContainerModal, {
          [styles.MediaContainerModalEmbed]: rest.embeddedMedia,
        })}
      >
        <VideoTileHighlight {...rest} />
      </div>
    </Modal>
  );
};

const VideoCarouselComponent = React.memo(
  ({
    id,
    tiles,
    rowTileCount,
    backgroundColor,
    fontColor,
    tileBackgroundColor,
    tileFontColor,
    moreEpisodesText,
    header,
    headerDisclaimer,
    contentCTAs,
  }: IVideoCarouselProps) => {
    const _rowTileCount = rowTileCount || 4;
    const [playingIndex, setPlayingIndex] = useState(-1);
    const portalManager = usePortals();
    const parentRef = useRef<HTMLDivElement>(null);
    const [carouselTiles, setCarouselTiles] = useState<typeof tiles>(tiles);
    const [largeTiles, setLargeTiles] = useState<typeof tiles>(
      tiles?.filter((x) => x.isLargeVideo)
    );
    const _contentCTAs = contentCTAs?.filter(
      (x) => !(x.ctaHidden || (isMobile && x.ctaHideInMobile))
    );

    const [isMobile, setIsMobile] = useState(
      useMediaQuery(
        { query: `(max-width: ${tabletMax}px)` },
        undefined,

        (matches) => {
          setIsMobile(matches);
        }
      )
    );
    useRTETooltipPositionCorrector(isMobile);

    const setTiles = useCallback(() => {
      if (tiles && tiles?.length > 0) {
        const idxs: number[] = [];
        const _largeTiles = tiles?.filter((x, index) => {
          if (x.isLargeVideo) {
            idxs.push(index);
          }
          return x.isLargeVideo;
        });
        const _tiles = tiles.filter((_, i) => !idxs.includes(i));
        setCarouselTiles(_tiles);
        if (_largeTiles.length > 0) {
          setLargeTiles(_largeTiles);
        }
      }
    }, []);
    useEffect(() => setTiles(), [setTiles, isMobile]);

    const showArrows = (compareCount = _rowTileCount) => {
      if (!(carouselTiles && carouselTiles.length > 0)) return false;
      return carouselTiles.length > compareCount;
    };

    const renderTiles = () => {
      if (!(carouselTiles && carouselTiles.length > 0)) return <></>;
      const refs = Array(carouselTiles.length)
        .fill(0)
        .map(() => React.createRef<HTMLVideoElement>());

      const handleVideoIconClick = (idx) => {
        refs.forEach((ref, i) => {
          onVideoPlayPause(
            ref.current,
            (e) => {
              if (i === idx) {
                setPlayingIndex(idx);
                e.play();
              }
            },
            (e) => {
              e.pause();
              if (i === idx) {
                setPlayingIndex(-1);
              }
            }
          );
        });
      };

      const handleModalView = (tile) => {
        portalManager.open(
          (portal) => <VideoTileModal closeModal={portal.close} {...tile} />,
          parentRef.current ? { appendTo: parentRef.current } : {}
        );
      };

      const handleViewMedia = (idx) => {
        const _tile = carouselTiles[idx];
        if (playingIndex === idx) {
          onVideoPlayPause(refs[idx].current);
          setPlayingIndex(-1);
        }
        if (playingIndex !== -1) {
          if (playingIndex === idx) {
            onVideoPlayPause(refs[idx].current);
          } else {
            onVideoPlayPause(refs[playingIndex].current);
          }
          setPlayingIndex(-1);
        }
        if (!isMobile) {
          handleModalView(_tile);
        }
      };

      return carouselTiles.map((tile, idx) => {
        return (
          <div
            key={idx}
            className={cn(styles.VideoTile, {
              [styles.VideoTileHover]: !isMobile,
            })}
            style={{
              ...(!isMobile
                ? { flexBasis: `${100 / _rowTileCount}%` }
                : { flexBasis: '100%' }),
            }}
            onClick={() => handleViewMedia(idx)}
          >
            <div>
              <div
                className={cn(styles.MediaContainer, {
                  [styles.MediaContainerEmbed]: tile.embeddedMedia,
                })}
                style={{
                  ...(tileBackgroundColor
                    ? {
                        backgroundColor: `#${tileBackgroundColor}`,
                      }
                    : {}),
                }}
              >
                {tile.embeddedMedia ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tile.embeddedMedia as string,
                    }}
                  />
                ) : tile.videoSrc ? (
                  <>
                    <video
                      ref={refs[idx]}
                      disablePictureInPicture
                      onEnded={(e: any) => {
                        onVideoEnded(e.target || refs[idx].current);
                        setPlayingIndex(-1);
                      }}
                      preload="auto"
                      src={tile.videoSrc}
                    />
                    <div>
                      <SvgIcon
                        type={
                          playingIndex === idx
                            ? IconEnum.pauseCircle
                            : IconEnum.playCircle
                        }
                        color={`#${colorWhite}`}
                        size={1.75}
                        onClick={(e) => {
                          handleVideoIconClick(idx);
                          e.stopPropagation();
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {(tile.embeddedMedia || tile.videoSrc) &&
                  renderDisclaimer(tile)}
              </div>
              {tile.header && (
                <div
                  style={{
                    ...(tileBackgroundColor
                      ? {
                          backgroundColor: `#${tileBackgroundColor}`,
                        }
                      : {}),
                    ...(tileFontColor ? { color: `#${tileFontColor}` } : {}),
                  }}
                  className={styles.ContentContainer}
                >
                  {tile.header && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${
                          tile.seriesName
                            ? `<h6>${tile.seriesName}&nbsp;</h6>`
                            : ''
                        }${tile.header}` as string,
                      }}
                    />
                  )}
                  {tile.description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: tile.description as string,
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        );
      });
    };

    return (
      <div
        ref={parentRef}
        key={id}
        id={`cid-${id}`}
        className={cn('full-device-width', styles.VideoCarouselComponent)}
        style={
          backgroundColor
            ? {
                backgroundColor: `#${backgroundColor}`,
              }
            : {}
        }
      >
        <MediaQuery minWidth={0}>
          {largeTiles && (
            <div
              className={styles.MainTileContainer}
              style={{
                ...(fontColor
                  ? {
                      color: `#${fontColor}`,
                    }
                  : {}),
              }}
            >
              {header && (
                <div className={styles.MainTileHeader}>
                  <div
                    style={{
                      ...(fontColor
                        ? {
                            color: `#${fontColor}`,
                          }
                        : {}),
                    }}
                    dangerouslySetInnerHTML={{ __html: header as string }}
                  />
                  {headerDisclaimer && (
                    <TooltipDisclaimer
                      triggerClassName={styles.HeaderDisclaimerTrigger}
                      disclaimer={headerDisclaimer}
                    />
                  )}
                </div>
              )}
              {largeTiles &&
                largeTiles.length > 0 &&
                largeTiles.map((_largeTile, index) => (
                  <Fragment key={index}>
                    <div
                      className={cn(styles.HighlightedMediaContainer, {
                        [styles.HighlightedMediaContainerEmbed]:
                          _largeTile.embeddedMedia,
                      })}
                      style={{
                        ...(_largeTile.videoSrc || _largeTile.embeddedMedia
                          ? { cursor: 'pointer' }
                          : {}),
                      }}
                    >
                      <VideoTileHighlight {..._largeTile} />
                    </div>
                    <div
                      className={cn(styles.MainTileContent, {
                        [styles.InheritColor]:
                          (tileFontColor || colorBlack) !== colorBlack,
                      })}
                      style={{
                        ...(tileBackgroundColor
                          ? {
                              backgroundColor: `#${
                                tileBackgroundColor || colorWhite
                              }`,
                            }
                          : {}),
                        ...(tileFontColor
                          ? {
                              color: `#${tileFontColor || colorBlack}`,
                            }
                          : {}),
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          `${_largeTile.header}${_largeTile.description}` as string,
                      }}
                    />
                  </Fragment>
                ))}
            </div>
          )}
          {carouselTiles && carouselTiles.length > 0 && (
            <div className={cn(styles.VideoCarouselContainer)}>
              {moreEpisodesText && (
                <div
                  className={styles.CarouselHeader}
                  style={{
                    ...(fontColor
                      ? {
                          color: `#${fontColor}`,
                        }
                      : {}),
                  }}
                  dangerouslySetInnerHTML={{
                    __html: moreEpisodesText as string,
                  }}
                />
              )}
              <div
                className={cn(styles.VideoCarousel, {
                  [styles.VideoCarouselCenter]:
                    !isMobile && carouselTiles.length < _rowTileCount,
                  [styles.VideoCarouselMobile]: isMobile,
                })}
              >
                <CustomCarousel
                  className={styles.CarouselContainerOverride}
                  carouselArrowColor={fontColor}
                  responsive={{
                    ...breakpoints,
                    desktop: {
                      ...breakpoints.desktop,
                      items: _rowTileCount,
                    },
                  }}
                  partialVisible={isMobile && showArrows(1)}
                  removeArrowOnDeviceType={['mobile']}
                  swipeable={isMobile}
                  arrows={!isMobile && showArrows()}
                >
                  {renderTiles()}
                </CustomCarousel>
              </div>
              <>
                {_contentCTAs && _contentCTAs.length > 0 && (
                  <div className={styles.VideoCarouselCTAs}>
                    {_contentCTAs?.map((cta, idx) => {
                      const buttonProps: any = {
                        type: toCamelCase(cta.ctaButtonType),
                        href: cta.ctaUrl?.url,
                        target: cta.ctaUrl?.target || '_self',
                      };
                      return (
                        <Button
                          key={idx}
                          className={cn(
                            styles.VideoCarouselCTA,
                            styles[`VideoCarouselCTA${buttonProps.type}`]
                          )}
                          buttonSize="medium"
                          {...buttonProps}
                        >
                          {cta.ctaIcon && cta.ctaIcon !== 'none' && (
                            <SvgIcon
                              className={styles.VideoCarouselCTAIcon}
                              type={cta.ctaIcon as keyof typeof IconEnum}
                              color={`#${colorPrimary}`}
                              size={1.2}
                              strokeWidth={2}
                            />
                          )}
                          <span
                            style={
                              buttonProps.type === 'text'
                                ? { color: `#${colorPrimary}` }
                                : {}
                            }
                          >
                            {cta.ctaUrl?.name || ''}
                          </span>
                        </Button>
                      );
                    })}
                  </div>
                )}
              </>
            </div>
          )}
        </MediaQuery>
      </div>
    );
  }
);
export const VideoCarousel = React.memo((props: IVideoCarouselProps) => {
  return (
    <PortalProvider>
      <VideoCarouselComponent {...props} />
    </PortalProvider>
  );
});
